<template>
  <div class="content">
    <div class="header">
      <a-select default-value @change="changeSelect" style="width: 200px;margin-right: 10px">
        <a-select-option value>全部类型</a-select-option>
        <a-select-option
          :value="item.value"
          v-for="item in typeList"
          :key="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>

      <a-range-picker @change="changeDate" :locale="locale" style="margin-right: 10px" />

      <a-input-search
        placeholder="搜索名称或ID"
        style="width: 200px;margin-right: 10px"
        @search="onSearch"
      />
      <a-button type="primary" @click="add()">添加</a-button>
    </div>

    <div class="main">
      <div style="background: #fff;padding:10px">
        <a-table
          :columns="columns"
          :row-key="record => record.code"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          :scroll="{ y: tableHeight }"
        >
          <template slot="status" slot-scope="status,record">
            <span v-if="status !== '0'">停用</span>
            <a-switch
              :default-checked="status === '0'"
              @change="val => changeStatus(val, record)"
              :loading="loadingStatus === record.code"
              style="margin: 0 10px;"
            />
            <span v-if="status === '0'">正常</span>
          </template>

          <template slot="cover" slot-scope="cover">
            <a :href="cover" target="_blank">
              <img :src="cover" alt style="width:100%" />
            </a>
          </template>

          <template slot="option" slot-scope="option,record">
            <a-popconfirm
              title="确定要删除该数据?"
              ok-text="确定"
              cancel-text="我再想想"
              @confirm="remove(record)"
            >
              <a-button type="link" :loading="loadingRemove === record.code" style="color: red">删除</a-button>
            </a-popconfirm>
            <a-button type="link" @click="add(record.code)">编辑</a-button>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleList, updateArticle, updateArticleStatus, getNewsCategory } from '@/api/admin'
import { DatePicker } from 'ant-design-vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'

export default {
  components: {
    [DatePicker.RangePicker.name]: DatePicker.RangePicker
  },
  data () {
    return {
      locale,
      moment,
      typeList: [],
      params: {
        page: 1,
        per_page: 10,
        search_data: '', // 标题搜索
        advisory_type: '', // 类型 1 公司新闻 2 行业动态
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      },
      data: [],
      pagination: {
        total: 0,
        current: 1,
        showLessItems: true
      },
      loading: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'code'
        },
        {
          title: '标题',
          dataIndex: 'title'
        },
        {
          title: '类型',
          dataIndex: 'advisory_type_str',
          width: '100px'
        },
        {
          title: '封面',
          dataIndex: 'title_image',
          scopedSlots: { customRender: 'cover' }
        },
        {
          title: '排序',
          dataIndex: 'sort',
          width: '80px'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center',
          width: '180px'
        },
        {
          title: '创建人',
          dataIndex: 'create_by'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'option',
          scopedSlots: { customRender: 'option' },
          width: '160px',
          align: 'center'
        }
      ],
      loadingRemove: false,
      loadingStatus: '',
      tableHeight: 0
    }
  },
  created () {
    this.getType()
    this.getList()
  },
  mounted () {
    this.tableHeight = document.body.clientHeight - 330
  },
  methods: {
    async getType() {
      const data = await getNewsCategory({
        classify_type: ['1']   // 1:新闻资讯 2:平台介绍 3:解决方案 4:关于我们
      })
      
      this.typeList = (data || []).map(item => (
        {
          name: item.name,
          value: item.code
        }
      ))
    },
    getList () {
      this.loading = true
      this.params.page = this.pagination.current
      getArticleList(this.params).then(res => {
        this.loading = false
        this.data = res.list
        this.pagination.total = res.count
      }).catch(err => {
        this.loading = false
        this.$message.error(err.msg)
      })
    },
    changeStatus (value, item) {
      this.loadingStatus = item.code
      updateArticleStatus({
        code: item.code,
        status: value ? '0' : '1'
      }).then(res => {
        this.loadingStatus = ''
        item.status = status
      }).catch(err => {
        this.loadingStatus = ''
        this.$message.error(err.msg)
      })
    },
    remove (item) {
      this.loadingRemove = item.code
      const params = Object.assign({}, item)
      params.status = '2'
      updateArticle(params).then(res => {
        this.loadingRemove = ''
        this.$message.success('删除成功')
        this.getList()
      }).catch(err => {
        this.loadingRemove = ''
        this.$message.error(err.msg)
      })
    },
    changeSelect (value) {
      this.params.advisory_type = value
      this.pagination.current = 1
      this.getList()
    },
    onSearch (value) {
      this.params.search_data = value
      this.pagination.current = 1
      this.getList()
    },
    changeDate (e, time) {
      this.params.start_time = time[0]
      this.params.end_time = time[1]
      this.pagination.current = 1
      this.getList()
    },
    add (code) {
      this.$router.push({
        name: 'ArticlesDetail',
        query: {
          code
        }
      })
    },
    handleTableChange (e) {
      this.pagination.current = e.current
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .header {
    position: fixed;
    background: #fff;
    right: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    padding: 0 30px;
    z-index: 1;
  }

  .main {
    padding: 15px;
    padding-top: 90px;
  }
}
</style>
